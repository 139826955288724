import React, { useEffect } from "react";
import { isEmpty, groupBy } from "lodash-es";
import Layout from "../components/layout/Layout";
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql, Link } from "gatsby";
import SalesQuestion from "../components/SalesQuestion";
import { pushToGTM } from "../helpers";


const scrollToContent = (elementId) => {
    const content = document.getElementById(elementId);
    if (!content) {
        window.requestAnimationFrame(scrollToContent);
    } else {
        content.scrollIntoView({ behavior: "smooth" });
    }
}

const Search = ({ data, location }) => {
    const { index, store } = data.localSearchPages;

    const [search, setSearch] = React.useState(location?.state?.searchValue || '');
    const [query, setQuery] = React.useState(search ? search : null);
    const [shouldShowClear, setShouldShowClear] = React.useState(search ? true : false);
    const results = useFlexSearch(query, index, store);
    const grouped = React.useMemo(() => groupBy(results, "type"), [results, query]);

    const pushSearchEventToGTM = () => {
        pushToGTM({
            'event': 'search',
            'search_term': search,
        });
    }

    useEffect(() => {
        const to = setTimeout(() => {
            if (query) {
                pushSearchEventToGTM();
                scrollToContent("search-results");
            }
        }, 0);

        return () => clearTimeout(to);
    }, [])

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setShouldShowClear(false);
    }

    const handleQueryChange = (e) => {
        e.preventDefault();
        e.persist();

        if (search) {
            setQuery(search);
            setShouldShowClear(true);
            pushSearchEventToGTM();
            scrollToContent("search-results");
        }
    }

    const handleClearSearch = (e) => {
        e.preventDefault();

        setSearch("");
        setQuery(null);
        setShouldShowClear(false);
    }

    const renderAdditionalTabs = () => {
        const content = ["process", "integration", "hyperautomation", "low-code", "frends"]
        const colors = ["purple", "green", "blue", "pink", "orange"]
        const handleUseTag = (tag) => {
            setQuery(tag);
            setSearch(tag);
            scrollToContent("search-results");
        }
        return (
            <div className="tags new-content-tag-container" style={{ justifyContent: "center" }}>
                <div className="columns is-multiline">
                    <div className="column is-4-tablet is-12-widescreen">
                        <div className="columns is-multiline has-text-centered">
                            {content.map((content, i) => (
                                <div className="column no-padding-tablet" key={i}>
                                    <div
                                        key={i}
                                        onClick={() => handleUseTag(content)}
                                        className={`tag margin-4 is-${colors[i]}-hover-tag-bg is-${colors[i]}-bg pointer`}
                                        style={{
                                            maxWidth: "100%",
                                            minHeight: "35px",
                                            transition: "all .2s ease",
                                        }}
                                    >
                                        <span
                                            className={`font-medium size-16 is-${colors[i]}`}
                                            style={{
                                                textOverflow: "ellipsis",
                                                /* Required for text-overflow to do anything */
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {content}
                                        </span>
                                        <div
                                            className={`is-${colors[i]}-arrow-with-spacing`}
                                        >
                                            <div className="line" />
                                            <div className="point" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderSections = () => {
        if (!isEmpty(query) && !isEmpty(grouped)) {
            return (
                <section className="section search-results-section">
                    <div className="container">
                        <div className="columns is-multiline">
                            <div className="column is-4">
                                <SearchResultMenu results={Object.keys(grouped)} />
                            </div>
                            <div className="column is-8">
                                <div className="search-results-section-content is-purple content">
                                    <SearchResultContent results={Object.entries(grouped)} query={query} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        } else if (!isEmpty(query) && isEmpty(grouped)) {
            return (
                <div className="container">
                    <div className="columns">
                        <div className="column has-text-centered">
                            <h3 className="is-purple size-38 margin-y-40">No results for {query} were found. Check out more:</h3>
                        </div>
                    </div>
                    {renderAdditionalTabs()}
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <Layout title="Search" pageContentId="search-content" location={location}>
            <main id="search-content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container has-text-centered">
                            <h1 className="title font-bold size-100 is-transparent text-stroke-purple">
                                find what you are
                            </h1>
                            <h2 className="title font-variable size-90 is-purple">
                                <span className="font-width-variation-870">loo</span>king <span className="font-width-variation-870">for</span>
                            </h2>
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth has-text-centered trial-form">
                                    <h3 className="size-30 is-purple padding-y-20">
                                        Search for articles, webinars and many more from our search box.
                                    </h3>
                                    <div className="field">
                                        <div className="control">
                                            <form onSubmit={handleQueryChange}>
                                                <input 
                                                    className="input search-input" 
                                                    type="text" 
                                                    placeholder="What do you need?" 
                                                    value={search} 
                                                    onChange={handleSearchChange} 
                                                    onKeyPress={handleSearchChange} 
                                                />
                                                {!shouldShowClear ? (
                                                    <button 
                                                        type="submit" 
                                                        className={`submit open`} 
                                                        aria-label="Search">
                                                    </button>
                                                ) : (
                                                    <button 
                                                        type="button" 
                                                        className={`submit close`} 
                                                        onClick={handleClearSearch} 
                                                        aria-label="Clear search input">    
                                                    </button>
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="search-results">
                    {renderSections()}
                </div>
                <SalesQuestion
                    color="green"
                    email="juha@frends.com"
                    name="Juha Moisio"
                    tel="+358 40 739 1412"
                    title="Global Partners"
                />
            </main>
        </Layout>
    )
}

const SearchResultContent = ({ results, query }) => {
    const getSlug = (entry, entryResult) => {
        if (entry === 'CoSellPartner' || entry === 'Glossary') {
            return `${entryResult.slug}`
        } else {
            return `/${entry.toLowerCase()}/${entryResult.slug}`
        }
    }

    return (
        <div>
            <h2 className="is-purple-tertiary size-28">search results for: <span className="is-purple">{query}</span></h2>
            {results.map((entry, i) => (
                <div id={`section-type-${entry[0]}`} key={i}>
                    <h2 className="is-purple size-60 margin-y-40">{entry[0]}</h2>
                    {entry[1].map((entryResult, idx) => (
                        <div className="block pointer search-result-item is-purple" key={idx}>
                            <Link to={getSlug(entry[0], entryResult)}>
                                <h2 className="is-purple">{entryResult.title}</h2>
                                <div className="columns is-vcentered">
                                    <div className="column is-10">
                                        <p className="no-margin is-purple">
                                            {entryResult.excerpt}
                                        </p>
                                    </div>
                                    <div className="column is-2">
                                        <div className="is-purple-arrow" style={{ width: "75px" }}>
                                            <div className="line" style={{ width: "75px" }}></div>
                                            <div className="point"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

const SearchResultMenu = ({ results }) => {
    const SearchResultMenuItem = ({ resultCategory }) => {
        const handleGoToCategory = () => {
            scrollToContent(`section-type-${resultCategory}`);
        }
        return (
            <li className="top-level-item is-purple">
                <div
                    className="top-level-title-container"
                    onClick={handleGoToCategory}
                    role="button" tabIndex={0}>
                    <span className={`top-level-title`}>{resultCategory}</span>
                </div>
            </li>
        )
    }

    return (
        <aside className="menu platform-menu sticky">
            <ul className="menu-list">
                {results.map((resultCategory, i) => (
                    <SearchResultMenuItem
                        key={i}
                        resultCategory={resultCategory}
                    />
                ))}
            </ul>
        </aside>
    )
}

export const query = graphql`
    query SearchPage {
        localSearchPages {
            index
            store
        }
    }
`

export default Search;